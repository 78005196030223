<template>
  <v-breadcrumbs :items="items" divider="" color="breadcrumbs">
    <template #divider>
      <v-icon :icon="deviderIcon" :color="deviderColor" :size="size"></v-icon>
    </template>
    <template #item="{ item }">
      <v-breadcrumbs-item v-bind="makeAttrs(item, $router)" v-on="makeClick(item, $router)">
        {{ item.title }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script setup>
const emit = defineEmits(["nodata"]);
const props = defineProps({
  items: { type: Array, default: () => [] },
  deviderIcon: { type: String, default: "" },
  deviderColor: { type: String, default: "#000" },
  size: { type: String, default: "x-small" },
});
const page = inject("page");

const { makeAttrs, makeClick } = useLead(page);
</script>
